<template>
  <header-component v-if="_auth"></header-component>

  <message v-if="socketMessage" :type="socketMessage"></message>

  <router-link class="new-post" to="/new-post" v-if="_auth">
    <span class="desktop"
      ><font-awesome-icon icon="plus" /> ITZULPEN BERRIA</span
    >
    <span class="mov"><font-awesome-icon icon="plus"/></span>
  </router-link>

  <div class="main-content">
    <router-view />
  </div>

  <footer-component v-if="_auth"></footer-component>
</template>

<script>
import HeaderComponent from "@/components/Header.vue";
import FooterComponent from "@/components/Footer.vue";
import Message from "@/components/Message";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    Message,
  },
  sockets: {
    newData(val) {
      if (val.user == this._auth.ID || this._auth.a) {
        if (val.type == 1) {
          this.socketMessage = "Iruzkin berria jaso duzu!";
        } else {
          this.socketMessage = "Dokumentu berria jaso duzu!";
        }

        setTimeout(() => {
          this.socketMessage = null;
        }, 6000);
      }
    },

    newItzulpena(val) {
      if (val && this.auth.a) {
        this.socketMessage = "Itzulpen eskaera berria jaso duzu!";
        setTimeout(() => {
          this.socketMessage = null;
        }, 6000);
      }
    },
  },
  data() {
    return {
      auth: null,
      socketMessage: null,
    };
  },
  mounted() {
    document.title = "Itzulpengintza zerbitzua";
    if (this.$cookie.getCookie("auth")) {
      this.auth = this.$cookie.getCookie("auth");
      this.$store.commit("_AUTH", this.auth);
    }
  },
  computed: {
    _auth() {
      return this.$store.state.auth;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

body {
  font-family: $font;
  font-size: 15px;
  font-weight: 700;
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-content {
  flex: 1 0 auto;
}

input,
textarea,
select,
button {
  font-family: $font;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  border: 0;
  border: 2px solid $borders;
  display: block;
  outline: 0;
  font-family: $font;
  transition: all 0.2s;
  &:focus {
    border-color: $blue;
  }
}

textarea {
  height: 200px;
}
button,
input[type="button"] {
  width: auto;
  border-color: black;
  padding: 10px 30px;
  background: transparent;
  color: black;
  &:hover {
    cursor: pointer;
  }
}

.title {
  &__one {
    position: relative;
    padding-bottom: 10px;
    font-size: 18px;

    @include from(md) {
      font-size: 36px;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background: black;
      width: 100px;
    }
  }

  &__two {
    margin: 50px 0;
    position: relative;
    padding-bottom: 10px;
    overflow: hidden;

    font-size: 18px;

    @include from(md) {
      font-size: 36px;
    }

    &:after {
      content: "";
      height: 2px;
      background: rgba(black, 0.1);
      width: 100%;
      position: absolute;
      top: 40%;
      margin-left: 30px;
    }
  }
}

.new-post {
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  background: $blue;
  font-weight: 800;
  color: white;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.2s;
  width: 40px;
  height: 40px;
  line-height: 40px;
  @include from(md) {
    width: auto;
    height: auto;
    padding: 10px 15px;
    line-height: 30px;
  }

  .desktop {
    display: none;
    @include from(md) {
      display: block;
    }
  }

  .mov {
    display: block;
    @include from(md) {
      display: none;
    }
  }
  &:hover {
    transform: scale(1.05);
    background: darken(#0078bf, 10%);
  }
}
.error {
  width: 100%;
  padding: 10px;
  color: $red;
  border-radius: 3px;
  border: 0;
  border: 1px solid $red;
  border-left: 5px solid $red;
  display: block;
  outline: 0;
  margin: 20px 0;
  transition: all 0.2s;
}

.success {
  width: 100%;
  padding: 10px;
  color: rgb(8, 167, 8);
  border-radius: 3px;
  border: 0;
  border: 1px solid rgb(8, 167, 8);
  border-left: 5px solid rgb(8, 167, 8);
  display: block;
  outline: 0;
  margin: 20px 0;
  transition: all 0.2s;
}

button,
input,
textarea {
  font-family: $font;
}

table {
  border-collapse: collapse;
  font-size: 13px;
  @include from(md) {
    font-size: 15px;
  }

  th {
    min-width: 120px;
    @include from(md) {
    }
  }
}

table,
th,
td {
  border: 0;
  text-align: left;
  padding: 10px;

  a {
    color: $red;
    text-decoration: none;
  }
}

th {
  background: black;
  color: white;
}

tr.closed {
  background: $red;
  color: white;

  td a {
    color: white;
    text-decoration: none;
  }
}

td {
  border-bottom: 2px solid rgba(black, 0.1);

  svg {
    margin: 0 5px;
  }
}

table {
  width: 100%;
}

.pagination {
  display: flex;
  justify-content: flex-start;

  button {
    padding: 5px;
    width: 30px;
    height: 30px;
    border: 0;
    background: rgba(black, 0.06);
    margin: 20px 2px;

    &.active {
      background: #111;
      color: white;
    }
  }
}
</style>
