import axios from 'axios';
import store from '../store'
import router from '../router'
import {
    VueCookieNext
} from "vue-cookie-next";

const interceptors = () => {


    axios.defaults.baseURL = process.env.VUE_APP_API;
    axios.interceptors.request.use(
        async (config) => {
                const token = VueCookieNext.getCookie('auth') //store.state.auth.token; //VueCookieNext.getCookie("auth")
                if (token) {
                    config.headers['Authorization'] = `Bearer ${ token.token }`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
    );

    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 401) {
            VueCookieNext.removeCookie('auth')
            store.commit("_AUTH", false);
            router.push("/");
        }

        return Promise.reject(error.response);
    });

    return axios;
}

export default interceptors;