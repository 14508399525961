<template>
  <div class="header">
    <div class="container">
      <div class="header__container">
        <div class="header__logo">
          <router-link
            v-if="!store.auth.a && store.auth.avatar"
            to="/all-posts-user"
          >
            <img :src="store.auth.avatar" />
          </router-link>
          <template v-else
            ><img v-if="store.auth.avatar" :src="store.auth.avatar"
          /></template>
          <div>
            <router-link v-if="!store.auth.a" to="/all-posts-user">
              <h1>{{ store.auth.user }}</h1>
            </router-link>
            <h1 v-else>{{ store.auth.user }}</h1>

            <div class="header__info">
              <template v-if="store.auth.a">
                <router-link to="/all-posts">Itzulpenak</router-link>
                <router-link to="/statistics">Estatistikak</router-link>

                <div class="header__exit-mov">
                  <a @click="_logout()">
                    <font-awesome-icon icon="sign-out-alt" />
                  </a>
                </div>
              </template>

              <template v-else>
                <div>Itzuli gabeak {{ this.noCompleted }}</div>
                <div>Itzulitakoak {{ this.completed }}</div>
                <div class="header__exit-mov">
                  <a @click="_logout()">
                    <font-awesome-icon icon="sign-out-alt" />
                  </a>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="header__exit">
          <button @click="_logout()">
            <font-awesome-icon icon="sign-out-alt" />
            Irten
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "Header",
  data() {
    return {
      completed: 0,
      noCompleted: 0,
    };
  },
  computed: {
    store() {
      return this.$store.state;
    },
  },
  mounted() {
    this._getPosts();
  },
  methods: {
    _logout() {
      this.$cookie.removeCookie("auth");
      this.$store.commit("_AUTH", false);
      this.$router.push("/");
    },
    _getPosts() {
      axios
        .get(
          "itzulpenak/v1/get-by-user?user=" +
            this.store.auth.ID +
            "&per_page=100&page=1"
        )
        .then((res) => {
          res.data.response.forEach((res) => {
            if (res.acf.itxita) {
              this.completed++;
            } else {
              this.noCompleted++;
            }
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

.header {
  background: rgba(black, 0.05);

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 15px 0;

    @include from(md) {
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;
      flex-direction: row;
    }
  }

  &__exit {
    display: none;
    @include from(md) {
      display: block;
    }
  }

  &__exit-mov {
    display: block;
    position: absolute;
    right: 10px;
    @include from(md) {
      display: none;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    margin-right: 10px;
    img {
      max-width: 50px;
      border-radius: 50%;
      margin-right: 10px;
      @include from(md) {
        max-width: 80px;
        margin-right: 20px;
      }
    }
    a {
      color: black;
      text-decoration: none;
    }
    h1 {
      font-size: 20px;
      margin: 0;
      @include from(md) {
        font-size: 30px;
      }
    }

    .header__info {
      display: flex;
      font-size: 13px;
      div {
        margin-right: 5px;
      }

      @include from(md) {
        margin: 5px 0 0 0;
        font-size: 14px;
        div {
          margin-right: 15px;
        }
      }

      a {
        color: black;
        text-decoration: none;
        margin-right: 10px;
        transition: all 0.2s;
        &:hover {
          color: $red;
        }
      }
    }
  }
}
</style>
