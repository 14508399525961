<template>
  <loader v-if="loader" type="Itzulpena gordetzen..."></loader>
  <div class="container form" :class="{ blur: loader }">
    <div class="row">
      <div class="col-md-12">
        <h1 class="title__two">Itzulpena berri bat eskatu</h1>
        <input type="text" v-model="izenburua" placeholder="Izenburua" />
      </div>

      <div class="col-md-6">
        <input type="text" v-model="saila" placeholder="Saila" />
      </div>

      <div class="col-md-6">
        <input type="text" v-model="bidaltzailea" placeholder="Bidaltzailea" />
      </div>

      <div class="col-md-12">
        <textarea v-model="oharra" placeholder="Oharrak"></textarea>

        <input
          type="file"
          ref="myFiles"
          placeholder="Dokumentua"
          @change="previewFiles"
        />

        <div class="error" v-if="error">{{ error }}</div>
        <div class="success" v-if="success">{{ success }}</div>

        <button @click="_save">Itzulpen berria eskatu</button>
      </div>
    </div>
  </div>
  <router-view />
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader";

export default {
  name: "NewPost",
  components: {
    Loader,
  },
  data() {
    return {
      loader: false,
      izenburua: null,
      saila: null,
      bidaltzailea: null,
      user: null,
      oharra: null,
      dokumentua: null,
      myFiles: null,
      itzulpenak: null,
      error: false,
      success: false,
      returnedPost: null,
    };
  },
  mounted() {
    document.title = "Itzulpen berria - Itzulpengintza zerbitzua";
    this.user = this.store.auth.ID;
  },
  computed: {
    store() {
      return this.$store.state;
    },
  },
  methods: {
    _sendMail() {
      let params = {
        post: this.returnedPost,
        type: "itzulpen-berria",
      };
      axios.post("itzulpenak/v1/send-email", params).then((res) => {
        console.log(res);
      });
    },
    previewFiles() {
      this.dokumentua = this.$refs.myFiles.files[0];
    },
    async _save(e) {
      e.preventDefault();
      this.loader = true;
      this.error = false;
      this.success = false;
      setTimeout(async () => {
        if (!this.izenburua || !this.dokumentua) {
          this.error = "Izenburua eta dokumentua bete.";

          this.loader = false;
          return true;
        }

        let formData = new FormData();
        formData.append("file", this.dokumentua);
        let document = await axios.post(`wp/v2/media`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        let params = {
          title: this.izenburua,
          status: "publish",
          fields: {
            user: this.user,
            saila: this.saila,
            bidaltzailea: this.bidaltzailea,
            oharra: this.oharra,
            dokumentua: document.data.id,
          },
        };
        axios
          .post("/wp/v2/itzulpena", params)
          .then((res) => {
            this.izenburua = null;
            this.saila = null;
            this.bidaltzailea = null;
            this.oharra = null;
            this.dokumentua = null;
            this.success = "Zure itzulpena ondo jaso dugu.";
            this.returnedPost = res.data;
            console.log(this.returnedPost);
            // emit socket data
            this.$socket.emit("new", true);
            // emit socket data

            this._sendMail();
          })
          .catch(() => {});

        this.loader = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";
button {
  margin-top: 20px;
}

.form {
  padding: 0;
  &.blur {
    filter: blur(5px);
  }
}

.form .col-md-6 {
  padding-top: 0;
}

.form textarea {
  margin-bottom: 20px;
}
</style>
