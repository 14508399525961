<template>
  <div class="message">
    <div v-if="type" class="message__text">{{ type }}</div>
  </div>
</template>

<script>
export default {
  props: ["type"],
};
</script>
<style lang="scss">
@import "@/assets/scss/styles.scss";

.message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: 0;
  border-bottom: 2px solid rgb(241, 216, 130);
  background: rgb(255, 229, 145);
  display: block;
  outline: 0;
  z-index: 999;
  transition: all 0.2s;
  &__text {
    text-align: center;
    padding: 20px 20px;
    font-size: 14px;
  }
}
</style>
