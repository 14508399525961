import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import NewPost from '../views/NewPost.vue'
import EditPost from '../views/EditPost.vue'


import {
  VueCookieNext
} from "vue-cookie-next";

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/new-post',
    name: 'NewPost',
    component: NewPost
  },
  {
    path: '/edit-post/:id',
    name: 'EditPost',
    component: EditPost,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/all-posts',
    name: 'AllPosts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/AllPosts.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/all-posts-user',
    name: 'AllPostsUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/AllPostsUser.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/statistics',
    name: 'Statistics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Statistics.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let auth = VueCookieNext.getCookie("auth")
    if (!auth) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    let auth = VueCookieNext.getCookie("auth")
    if (auth) {
      if (!auth.a) {
        next({
          path: '/'
        })
      } else {
        next()
      }
    } else {
      next() // make sure to always call next()!
    }
  } else {
    next() // make sure to always call next()!
  }
});

export default router