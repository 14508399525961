import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-3-socket.io'

import {
    VueCookieNext
} from 'vue-cookie-next'

import interceptors from './plugins/interceptors'

interceptors();


/* font awesome free */
import {
    library
} from "@fortawesome/fontawesome-svg-core";

import {
    faSignOutAlt,
    faSignInAlt,
    faPlus,
    faArrowCircleRight,
    faArrowCircleDown
} from "@fortawesome/free-solid-svg-icons";

import {
    faComments,
    faFileAlt
} from "@fortawesome/free-regular-svg-icons";

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";


library.add([faComments, faFileAlt, faSignOutAlt, faSignInAlt, faPlus, faArrowCircleRight, faArrowCircleDown]);
/* font awesome free */


const socketio = new VueSocketIO({
    debug: true,
    connection: process.env.VUE_APP_SOCKET
});

createApp(App).use(store).use(router).use(VueCookieNext).use(socketio).component("font-awesome-icon", FontAwesomeIcon).mount('#app')