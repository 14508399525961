<template>
  <div class="loader"></div>
  <div class="loader__card">
    <img alt="Vue logo" src="../assets/img/loader.svg" />
    <div v-if="type" class="loader__text">{{ type }}</div>
  </div>
</template>

<script>
export default {
  props: ["type"],
};
</script>
<style lang="scss">
@import "@/assets/scss/styles.scss";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  &__card {
    position: fixed;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 250px;
    z-index: 999;
    // border: 3px solid rgba(black, 0.08);
    // border-radius: 5px;
    // box-shadow: 0 0 20px rgba(black, 0.1);
    // background: white;
  }

  &__text {
    text-align: center;
    background: white;
    padding: 10px 20px;
    border-radius: 5px;
  }
}
</style>
