<template>
  <div class="container">
    <loader v-if="loader" :type="dataName"></loader>
    <div class="posts" v-if="itzulpenak" :class="{ blur: loader }">
      <div
        class="post-title"
        :class="{ 'post-title__closed': itzulpenak.acf.itxita }"
      >
        <div class="post-title__header">
          <div>
            <h1>{{ itzulpenak.title.rendered }}</h1>
          </div>
          <div class="post-title__status">
            {{ itzulpenak.acf.itxita ? "itxita" : "zabalik" }}
          </div>
        </div>

        <div class="post-title__desc">
          <div v-if="itzulpenak.acf.dokumentua">
            <font-awesome-icon :icon="['far', 'file-alt']" />
            <a
              :href="itzulpenak.acf.dokumentua.url"
              target="_blank"
              class="download-button"
            >
              {{ itzulpenak.acf.dokumentua.name }} [
              {{ itzulpenak.acf.dokumentua.subtype }} -
              {{ (itzulpenak.acf.dokumentua.filesize / 1024).toFixed(0) }}Kb ]
            </a>
          </div>
          <div>
            <font-awesome-icon :icon="['far', 'comments']" />
            {{ itzulpenak.acf.oharra }}
          </div>
        </div>

        <div class="post-title__extras">
          <div><strong>Bezeroa:</strong> {{ user }}</div>
          <div><strong>Saila:</strong> {{ itzulpenak.acf.saila }}</div>
          <div>
            <strong>Bidaltzailea:</strong> {{ itzulpenak.acf.bidaltzailea }}
          </div>
        </div>
      </div>

      <template v-for="(item, index) in itzulpenak.acf.edukia" :key="index">
        <div
          class="post-comment"
          :class="{
            'post-comment__user-back': adminList.includes(
              item.erabiltzailea.ID
            ), //3 == item.erabiltzailea.ID
          }"
        >
          <template v-if="item.acf_fc_layout == 'iruzkina'">
            <div>
              <font-awesome-icon :icon="['far', 'comments']" />
            </div>
            <div>
              <span>{{ item.erabiltzailea.nickname }}:</span>
              {{ item.iruzkina }}
            </div>
          </template>

          <template v-else>
            <div>
              <font-awesome-icon :icon="['far', 'file-alt']" />
            </div>
            <div>
              <span>{{ item.erabiltzailea.nickname }}:</span>
              <a
                v-if="item.dokumentua"
                :href="item.dokumentua.url"
                class="download-button"
                target="_blank"
              >
                <font-awesome-icon :icon="['fas', 'arrow-circle-down']" />
                {{}}
                {{ item.dokumentua.name }}
                [ {{ item.dokumentua.subtype }} -
                {{ (item.dokumentua.filesize / 1024).toFixed(0) }}Kb ]
              </a>
            </div>
          </template>
        </div>
      </template>

      <div v-if="dialog" class="post-comment__box">
        <h3 v-if="isIruzkina" class="">Iruzkin berria</h3>
        <h3 v-else class="">Dokumentu berria</h3>

        <div class="error" v-if="error">{{ error }}</div>

        <!-- iruzkina -->
        <textarea
          v-if="isIruzkina"
          v-model="iruzkina"
          placeholder="Iruzkina"
        ></textarea>

        <!-- dokumentua -->
        <input
          v-else
          type="file"
          ref="myFiles"
          placeholder="Dokumentu bat igo"
          @change="previewFiles()"
        />
      </div>

      <div class="success" v-if="success">{{ success }}</div>

      <div
        class="action action__comments"
        v-if="!dialog && !itzulpenak.acf.itxita"
      >
        <button @click="_isIruzkina(1)">
          <font-awesome-icon icon="plus" />
          IRUZKIN BERRIA
        </button>
        <button @click="_isIruzkina(0)">
          <font-awesome-icon icon="plus" />
          DOKUMENTU BERRIA
        </button>
        <button @click="_closeItzulpena(itzulpenak.id)" class="close">
          <font-awesome-icon icon="plus" />
          ITXI
        </button>
      </div>

      <div class="action action__comments" v-if="dialog">
        <button @click="_save">Gorde</button>
        <button @click="_cancel" class="cancel">Ezeztatu</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader";
import { AdminList } from "@/plugins/adminList";

export default {
  name: "EditPost",
  components: {
    Loader,
  },
  sockets: {
    newData(val) {
      if (val.user == this.store.auth.ID || this.store.auth.a) this._load();
    },
  },
  data() {
    return {
      loader: true,
      adminList: AdminList,
      acf_fc_layout: null,
      user: null,
      erabiltzailea: null,
      iruzkina: null,
      dokumentua: null,
      isIruzkina: true,
      dialog: false,
      itzulpenak: null,
      error: false,
      success: false,
      dataName: "Datuak kargatzen...",
      returnedPost: null,
    };
  },
  mounted() {
    setTimeout(async () => {
      await this._load();
      document.title =
        this.itzulpenak.title.rendered + " - Itzulpengintza zerbitzua";
    }, 1500);

    this.erabiltzailea = this.store.auth.ID;
  },
  computed: {
    store() {
      return this.$store.state;
    },
  },
  methods: {
    _sendMail() {
      let params = {
        post: this.returnedPost,
        type: "dokumentu-berria",
      };
      axios.post("itzulpenak/v1/send-email", params).then(() => {});
    },
    async _load() {
      this.loader = true;

      // ez utzi POST-a erakusten erabiltzaileei
      try {
        var post = await axios.get(`wp/v2/itzulpena/${this.$route.params.id}`);
      } catch (err) {
        this.$router.push("/");
      }

      if (post.data.author == this.store.auth.ID || this.store.auth.a) {
        let user = await axios.get(
          `itzulpenak/v1/get-user?id=${post.data.author}`
        );

        this.user = user.data.response;
        this.itzulpenak = post.data;
        this.loader = false;
      } else {
        this.$router.push("/");
      }
    },
    _isIruzkina(n) {
      this.isIruzkina = n;

      if (n) {
        this.acf_fc_layout = "iruzkina";
        this.dataName = "Iruzkina gordetzen...";
      } else {
        this.acf_fc_layout = "dokumentua";
        this.dataName = "Dokumentua gordetzen...";
      }
      this.dialog = true;
    },
    previewFiles() {
      this.dokumentua = this.$refs.myFiles.files[0];
    },

    async _save(e) {
      e.preventDefault();
      this.loader = true;
      this.error = false;
      let document = null;

      if (this.isIruzkina) {
        if (!this.iruzkina || !this.erabiltzailea) {
          this.error = "Eremu guztiak bete mesedez!";
          this.loader = false;
          return true;
        }
      } else {
        if (!this.dokumentua || !this.erabiltzailea) {
          this.error = "Eremu guztiak bete mesedez!";
          this.loader = false;
          return true;
        }

        let formData = new FormData();
        formData.append("file", this.dokumentua);
        let upload = await axios.post(`wp/v2/media`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        document = upload.data.id;
      }

      if (this.error) return;

      if (!this.itzulpenak.acf.edukia) this.itzulpenak.acf.edukia = [];

      this.itzulpenak.acf.edukia.push({
        acf_fc_layout: this.acf_fc_layout,
        erabiltzailea: this.erabiltzailea,
        iruzkina: this.iruzkina,
        dokumentua: document,
      });
      let params = {
        fields: {
          edukia: this.itzulpenak.acf.edukia,
        },
      };

      axios
        .put(`/wp/v2/itzulpena/${this.$route.params.id}`, params)
        .then((res) => {
          this.iruzkina = null;
          this.dokumentua = null;
          this.dialog = false;
          this.returnedPost = res.data;
          // emit socket data
          this.$socket.emit("emiting", {
            type: this.isIruzkina,
            user: this.itzulpenak.author,
          });
          // emit socket data

          // emaila bidali erabiltzaileari
          // administrariak igotako dokumentua denean
          if (!this.isIruzkina && this.store.auth.a) this._sendMail();

          this._load();
        })
        .catch(() => {
          this.loader = false;
        });
    },

    _cancel() {
      this.dialog = false;
      this.error = false;
      this.iruzkina = null;
      this.dokumentua = null;
    },
    _closeItzulpena(id) {
      this.loader = true;
      this.success = false;

      this.dataName = "Itzulpena izten...";
      if (!id) return true;

      setTimeout(() => {
        let params = {
          fields: {
            itxita: true,
          },
        };

        axios
          .put(`/wp/v2/itzulpena/${id}`, params)
          .then(() => {
            this._load();
            this.success = "Itzulpena hau itxi egin da.";
          })
          .catch(() => {
            this.loader = false;
          });
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

.posts {
  &.blur {
    filter: blur(5px);
  }
}

.post-title {
  border: 2px solid $red;
  border-radius: 5px;
  margin: 30px 0;

  h1 {
    font-size: 18px;
    @include from(md) {
      font-size: 28px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $red;
    padding: 5px 15px;
    color: white;
  }

  &__status {
    background: white;
    color: $red;
    text-transform: uppercase;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .download-button {
    color: $red;
    background: transparent;
    font-weight: 800;
  }

  &__closed {
    border-color: lighten(black, 10%);
    .post-title__header {
      background: lighten(black, 10%);
    }
    .post-title__status {
      color: lighten(black, 10%);
    }

    .post-title__desc {
      color: lighten(black, 10%);
    }

    .download-button {
      color: lighten(black, 10%);
    }
  }

  &__desc {
    border-bottom: 1px solid rgba(black, 0.1);
  }
  &__desc,
  &__extras {
    display: block;
    padding: 30px 15px;
    color: $red;

    @include from(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    div {
      max-width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @include from(md) {
        max-width: 50%;
      }

      svg {
        transform: scale(1.4);
        margin: 0 20px 0 0;
        @include from(md) {
          transform: scale(2.5);
          margin: 0 30px 0 10px;
        }
      }
    }
  }

  &__extras {
    div {
      position: relative;
      max-width: 30% !important;
      color: black;
      padding: 0 10px;
      margin: 0;
      strong {
        margin-right: 5px;
        font-weight: 800;
      }
      &:before {
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        left: 0;
        background: $red;
      }
    }
  }

  h1 {
    color: white;
  }
}

.post-comment {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: lighten(black, 90%);
  padding: 30px 15px;
  color: black;
  margin: 30px 0;
  border-radius: 5px;
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background: lighten(black, 90%);
    position: absolute;
    transform: rotate(45deg) translateY(-50%);
    right: 0;
    z-index: -1;
    top: 50%;
  }
  span {
    margin-right: 5px;
    font-weight: 800;
  }

  div {
    font-weight: 500;
  }
  &__box {
    padding: 15px;
    background: rgba($blue, 0.08);
    border: 2px solid rgba($blue, 0.3);
    border-radius: 3px;
  }
  svg {
    transform: scale(1.4);
    margin: 0 10px;
    @include from(md) {
      transform: scale(2.5);
      margin: 0 30px 0 10px;
    }
  }
  &__user-back {
    flex-direction: row-reverse;
    background: lighten(black, 10%);
    color: white;

    .download-button {
      color: white;
      transition: all 0.2s;
      &:hover {
        color: $red;
      }
    }
    &:before {
      left: -15px;
      background: lighten(black, 10%);
    }
  }
}

.download-button {
  width: auto;
  font-weight: 800;
  color: black;
  background: transparent;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    color: $red;
  }
  svg {
    transform: scale(1);
    margin: 0;
  }
}

.action {
  display: flex;
  justify-content: center;
  margin: 30px 0;

  button {
    margin: 0 5px;
    text-transform: uppercase;

    &.cancel {
      background: transparent;
      color: $red;
      border-color: $red;
    }
  }

  &__comments {
    button {
      background: $blue;
      color: white;
      border-color: rgba($blue, 0.5);
    }
  }
}
</style>
