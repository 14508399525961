<template>
  <div class="footer">
    <div class="container">
      <div class="footer__container">
        <div class="footer__logo">
          <img alt="Vue logo" src="../assets/img/logo.svg" />
        </div>

        <div class="footer__desc">
          Artez Euskara Zerbitzua Koop. E. | Luzarra, 10 – Bilbo - Bizkaia,
          Euskal Herria | 944 480 000 - posta@artez.eus
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Footer",
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

.footer {
  margin-top: 50px;
  &__container {
    border-top: 2px solid rgba(black, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  &__logo {
    display: flex;
    align-items: center;

    img {
      max-width: 150px;
      margin: 15px 0;
    }
  }

  &__desc {
    font-size: 12px;
    text-align: center;
  }
}
</style>
