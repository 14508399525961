<template>
  <div>
    <loader v-if="loader" type="Saioa hasten..."></loader>
    <div class="home" :class="{ blur: loader }">
      <div class="login">
        <div class="login__form">
          <div class="container">
            <div class="row">
              <div class="col-md-offset-6 col-md-6">
                <h1 class="title__one">Itzulpengintza</h1>

                <div class="error" v-if="error">{{ error }}</div>

                <form action="">
                  <input
                    type="text"
                    v-model="username"
                    placeholder="Erabiltzaile izena"
                  />
                  <input
                    type="password"
                    v-model="password"
                    placeholder="Pasahitza"
                  />
                  <button type="submit" @click="login">
                    <font-awesome-icon icon="sign-in-alt" /> Saioa hasi
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Loader from "@/components/Loader";
import { AdminList } from "@/plugins/adminList";

export default {
  name: "Home",
  components: { Loader },
  data() {
    return {
      username: null,
      password: null,
      error: null,
      loader: false,
    };
  },
  sockets: {},
  mounted() {
    document.title = "Hasiera - Itzulpengintza zerbitzua";
    if (this.store.auth) {
      if (this.store.auth.a) {
        this.$router.push("/all-posts");
      } else {
        this.$router.push("/all-posts-user");
      }
    }
  },
  computed: {
    store() {
      return this.$store.state;
    },
  },
  methods: {
    async login(e) {
      e.preventDefault();

      if (!this.username || !this.password) return;

      this.loader = true;
      this.error = null;
      let params = {
        username: this.username,
        password: this.password,
      };
      setTimeout(async () => {
        try {
          let res = await axios.post("/jwt-auth/v1/token", params);
          this.loader = false;
          let isAdmin = 0;
          let avatar = null;

          if (AdminList.includes(parseInt(res.data.ID))) {
            isAdmin = 1;
          }

          if (res.status === 200) {
            let user = await axios.get(`acf/v3/users/${res.data.ID}`, params);

            if (user.data.acf.avatar)
              avatar = user.data.acf.avatar.sizes.thumbnail;

            let authObject = {
              token: res.data.token,
              ID: res.data.ID,
              user: res.data.user_nicename,
              a: isAdmin,
              avatar: avatar,
            };
            this.$cookie.setCookie("auth", authObject);
            this.$store.commit("_AUTH", authObject);

            isAdmin
              ? this.$router.push("/all-posts")
              : this.$router.push("/all-posts-user");
            return true;
          }
        } catch (error) {
          this.error = "Erabiltzaile edo pasahitz okerrak";
          this.loader = false;
        }
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

.home {
  background: white url(../assets/img/background.png);
  background-repeat: no-repeat;
  background-position: left center;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.blur {
    filter: blur(8px);
  }
}

input {
  margin: 0 0 15px 0;
}

.login {
  width: 100%;

  button {
    margin-top: 20px;
  }
}
</style>
