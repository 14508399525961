import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    auth: null,
  },
  mutations: {
    _AUTH(state, val) {
      state.auth = val
    },
  },
  actions: {},
  modules: {}
})